import React, { useEffect, useState } from "react"
import Form from "../components/contact-form"
import { getContentfulSectionData } from "../helpers"
import Container from "../components/container"
import Row from "../components/row"
import Text from "../components/text"
import Column from "../components/column"

export default function ContactB2B({ data }) {
  const [formData, setformData] = useState()
  const [showformselection, setShowFormSelection] = React.useState(true)

  useEffect(() => {
    let formdata = getContentfulSectionData({
      sectionList: data.allContentfulSection.edges,
      sectionSlug: "page-section-or-contact-us-b2b",
    });
    let b2bdata = Object.assign(formdata)
    if (formdata?.detailField) {
      b2bdata.defaultFields = {
        ...b2bdata.defaultFields,
        [b2bdata.detailField]: `Contact Form - B2B`,
      }
    }
    setformData(b2bdata)
  }, [data])

  const hideFormSelection = () => {
    setShowFormSelection(false)
  }

  const rowStyle = {
    top: { mt: theme => [3, 3, 3, theme.spacing.vertical.lg] },
    bottom: { mt: [0, 0, 8] },
  }
  return (
    <Container
      customStyle={{
        pb: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
        ],
        mb: theme => [theme.spacing.vertical.md],
      }}
    >
      {showformselection && (
        <Row customStyle={{ ...rowStyle["top"], pt: [30, 30, null, null] }}>
          <Column size={[12, 12, 12, 4]} customStyle={{ pl: [10, 10, 0, 0] }}>
            <Text type="h2" customStyle={{ fontSize: [40, 40, 56] }}>
              Contact Us
            </Text>
            <Text customStyle={{ mt: 3 }}>
              Please take a few minutes to tell us about yourself and how we can
              help you.
            </Text>
          </Column>
        </Row>
      )}
      {formData && (
        <Container
          customStyle={{
            pl: [0, 0, 10, 0],
          }}
        >
          <Row
            customStyle={{
              zIndex: 2,
              pl: 0,
            }}
          >
            <Column
              customStyle={{
                mt: theme => [theme.spacing.vertical.md],
                mr: theme => [, theme.spacing.vertical.sm, 94],
                pl: [10, 10, 0, 0],
              }}
              size={[12, 12, 12]}
            >
              <Form
                customStyle={{ px: 0, pl: [0, null, null] }}
                formData={formData}
                moveToTopOnSubmit={true}
                hideFormSelection={hideFormSelection}
              ></Form>
            </Column>
          </Row>
        </Container>
      )}
    </Container>
  )
}

export const query = graphql`
  query contactUsB2B {
    allContentfulSection(
      filter: { slug: { eq: "page-section-or-contact-us-b2b" } }
    ) {
      edges {
        node {
          content {
            internal {
              content
            }
          }
          slug
        }
      }
    }
  }
`
